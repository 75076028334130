import {Avatar} from '@halp/ui';
import type {StudentType} from '@halp/util/constants';
import type {ComponentProps} from 'react';

interface Props {
	first: string;
	size: ComponentProps<typeof Avatar>['size'];
	studentType: StudentType;
	className?: string;
}

export function StudentAvatar({first, size, className, studentType}: Props) {
	let color: ComponentProps<typeof Avatar>['color'] = 'primary';
	if (
		studentType === 'ONSHORE' ||
		studentType === 'ONSHORE_EXTENDER' ||
		studentType === 'ONSHORE_SWITCHER'
	) {
		color = 'secondary';
	} else if (studentType === 'DOMESTIC') {
		color = 'pink';
	}

	return (
		<Avatar size={size} text={first} color={color} className={className} />
	);
}
